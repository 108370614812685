:root {
  --color: #383a3c;
}

body {
  font-family: "Urbanist", sans-serif;
}
.pp {
  text-transform: capitalize !important;
}
p {
  font-size: 18px;
}
.common-link {
  color: inherit !important;
}
.common-link:hover {
  color: var(--color) !important;
  text-decoration: none !important;
}
Link:hover {
  text-decoration: none !important;
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.content-wrap {
  flex: 1;
}

.gif {
  border-radius: 20px;
}

/* Button & input ,textarea style  */

button:focus {
  border: none !important;
  box-shadow: none !important;
}
input:focus {
  box-shadow: none !important;
  border: 1px solid lightgray !important;
}

input {
  font-size: 17px !important;
  height: 38px;
}

/* common button css  */
.common-btn {
  background-color: var(--color);
  border: none;
  padding: 5px 28px;
  font-size: 18px;
  border-radius: 5px;
  cursor: pointer;
}
.common-btn:hover {
  background-color: #101010 !important;
  color: white !important;
}

button {
  font-size: 19px;
}
button:focus {
  border: none !important;
  outline: none !important;
}

.nft-btn:hover {
  background-color: #101010;
}

textarea:focus {
  outline: none !important;
  box-shadow: none !important;
}

/* end button & input css */

/* labels style  */

.home-labels {
  font-size: 20px;
  color: #101010;
  font-weight: bold;
}
.forms-label {
  font-size: 15px;
  font-weight: bold;
  color: #101010;
}
.forms-labels {
  color: var(--color);

  font-family: Urbanist;
  font-size: 17px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
}

/* end label style  */

/* dialog box CSS */
.MuiDialogContent-dividers {
  padding: 0px !important;
  border: 0 !important;
  padding-left: 27px !important;
  padding-right: 27px !important;
}
.MuiDialogActions-spacing {
  padding: 16px 24px !important;
}
.closeicon {
  padding-right: 13px !important;
}
.MuiIconButton-sizeMedium {
  right: 17px !important;
}
.MuiTypography-body1 {
  padding-left: 3px !important;
}

.dialog-btn {
  background-color: #101010 !important;
  border: none !important;
  padding: 5px 28px !important;
  font-size: 18px !important;
  color: white !important;
  border-radius: 5px;
  cursor: pointer;
}

/* DIALOG BOX CSS END  */

/* common-row */
.common-row {
  padding-bottom: 2rem;
  padding-top: 2rem;
}

/* common css .join-telegram */

.join-telegram-parent {
  padding-right: 17px;
}

@media only screen and (max-width: 592px) {
  .container,
  .complete-account-row,
  .userbio-account-row,
  .forget-common-parent {
    width: 92%;
    box-shadow: none !important;
  }
  /* forget password css  */
  .forget-common-parent {
    padding: 0px 9px !important;
  }

  .complete-account-row {
    padding: 0 !important;
  }
  /* error pagev css  */
  .error-text {
    font-size: 100px !important;
  }
  .complete-account-btn,
  .userbio-account-btn {
    width: 100%;
    margin: 8px 0px;
  }
  /* .mobile-logo {
    height: 1.5rem !important;
  } */
  .join-telegram {
    font-size: 17px;
  }
  .header-btn {
    padding: 5px 16px;
  }

  .nftBox {
    padding: 11px 23px !important;
  }
  .nft-btn {
    padding: 5px 16px !important;
  }
  .video-section {
    border-bottom: 1px solid #f3f3f3;
  }
  .home-section-second {
    padding-top: 2rem !important;
    padding-left: 2rem !important;
  }
  .common-row {
    padding-bottom: 1rem !important;
    padding-left: 0 !important;
    padding-right: 0rem !important;
    padding-top: 1rem !important;
  }

  .common-btn {
    width: 100%;
  }

  .divide-row {
    display: block !important;
  }
  .register-btn-section {
    display: flex;
  }
  .register-btn {
    flex: 1 !important;
  }
  .register-btndisable {
    flex: 1 !important;
  }
  .login-btn-section {
    display: flex;
  }
  .login-btn {
    flex: 1 !important;
  }
  .verifybtn-section {
    display: flex;
  }
  .verifyemail-btn {
    flex: 1 !important;
  }
}

.notready {
  display: none;
}

.ready {
  display: block;
}

.mobile-logo {
  height: 120px;
  width: 400px;
}
.mobile-logo1 {
  height: 120px;
  width: 400px;
}
.cursor-pointer {
  cursor: pointer;
}
.common-color {
  color: var(--color);
}
li {
  font-size: 18px;
}
.common-footer-heading {
  border-bottom: 2px solid var(--color);
}

/* .button {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #0062cc;
} */

/* .spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -8px;
  margin-left: -8px;
  width: 16px;
  height: 16px;
  border: 2px solid #fff;
  border-top-color: #007bff;
  border-radius: 50%;
  animation: spinner 0.8s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.button:active .spinner {
  opacity: 1;
}

@keyframes spinner {
  to {transform: rotate(360deg);}
} */
