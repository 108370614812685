.buy-nft-img {
  width: 100%;
  border-radius: 20px;
  width: 340px !important;
  height: 356px !important;
}

.blured-nft {
  filter: blur(5px);
}

.buy-title {
  font-size: 20px;
}

.buy-now-box {
  margin: 0px 26px;
  position: relative;
  top: -6rem;
  background: white;
  border-radius: 20px;
  box-shadow: 1px 1px 8px 1px #888888;
}
.collection-box {
  margin: 0px 26px;
  position: relative;
  top: -3rem;
  height: 120px;
  background: white;
  border-radius: 20px;
  box-shadow: 1px 1px 8px 1px #888888;
}
.buy-heading {
  color: #6f6f6f;
  font-size: 14px;
}
.buy-btn button {
  background: #7bd7ab;

  border-radius: 8px;
  padding: 9px 31px 9px 31px;
  border: none;
  font-size: 16px;
  font-weight: 600;
}
.buy-user-img {
  height: 49px;
  width: 49px;
}

.unlockText {
  font-size: 18px;
  white-space: normal;
  /* font-weight: 600;
  color: #6f6f6f; */
  /* width: 250px; */
}
@media only screen and (max-width: 408px) {
  .buy-now-box {
    margin: 0px 18px;
  }
  .bio-img {
    width: 127px;
    height: 127px;
  }

  .second-container {
    padding-top: 2rem;
  }
  .auth-container {
    padding-top: 2rem;
  }
  .first-btn {
    margin-top: 5px !important;
    width: 100%;
  }
  .second-btn {
    margin-top: 5px !important;
    width: 100%;
  }
  .second-msg {
    margin-bottom: 0px !important;
  }
  .third-btn {
    width: 100%;
    margin-top: 17px !important;
  }
  .payment-btn {
    width: 100%;
  }
}
@media only screen and (max-width: 592px) {
  .username-title {
    font-size: 24px;
  }
  .username-desc {
    font-size: 16px;
  }
}

.short-detail {
  position: relative;
  right: 17px;
}

.account-desc {
  font-size: 13px;
  font-weight: bold;
}
.userinfo-labels {
  font-size: 13px;
}

@media only screen and (max-width: 992px) {
  .second-msg {
    margin-bottom: 0px !important;
  }
  .third-btn {
    margin-top: 17px !important;
  }
  .second-container {
    padding-top: 3rem;
  }
  .auth-container {
    /* padding-top: 3rem; */
  }
  .first-btn {
    margin-top: 5px !important;
  }
  .second-btn {
    margin-top: 5px !important;
  }
  .second-msg {
    margin-bottom: 0px !important;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .second-btn {
    margin-top: 30px !important;
  }
  .third-btn {
    margin-top: 161px !important;
  }
  .buy-now-box {
    margin: 0px 15px !important;
  }
}
.blockchain-labels {
  font-size: 19px;
}

.wallet-address {
  border-bottom: 1px solid lightgray;
  color: #999999;
  font-size: 16px;
}

/* collection  */
.empty-nft {
  background-color: lightgray;
  height: 400px;
  border-radius: 20px;
}

.empty-nft h4 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  transform: rotate(336deg);
  font-size: 90px;
  color: #b1b0b0;
  font-weight: bold;
  letter-spacing: 6px;
}

@media only screen and (max-width: 360px) {
  .view-nft-second-div {
    flex-direction: column;
  }
}
@media only screen and (max-width: 592px) {
  .buy-nft-img {
    height: unset;
  }
}
@media only screen and (max-width: 992px) {
  .second-msg {
    display: none !important;
  }
}
@media only screen and (max-width: 768px) {
  .second-msg {
    display: none !important;
  }
}
.logout-icon:hover {
  color: black;
  font-weight: bold;
}

.spinner {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-top-color: #000;
  border-radius: 50%;
  animation: spin 0.8s ease-in-out infinite;
  margin-right: 8px;
  margin-left: 12px;
  margin-bottom: -2px;
}

.react-tagsinput-tag {
  background-color: #cde69c !important;
  border-radius: 2px !important;
  border: 1px solid #a5d24a !important;
  color: #638421 !important;
  display: inline-block !important;
  font-family: sans-serif !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  margin-bottom: 5px !important;
  margin-right: 5px !important;
  padding: 5px !important;
}

.react-tagsinput-input {
  background: transparent !important;
  border: 0 !important;
  color: #777 !important;
  font-family: sans-serif !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  margin-bottom: 6px !important;
  margin-top: 1px !important;
  outline: none !important;
  padding: 5px !important;
  width: 150px !important;
}

.react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
