.title {
  color: var(--color);
  font-weight: 700;
}
.text {
  font-size: 19px;
}
.complete-account-btn {
  background-color: var(--color);
  border: none;
  padding: 5px 28px;
  font-size: 18px;
}

.slidez {
  border-top: 9px solid var(--color);
  border-width: 3px !important;
  margin-right: 1rem;
  width: 65px;
}
.slidey {
  border-top: 9px solid var(--color);
  border-width: 3px !important;
  margin-right: 1rem;
  width: 65px;
}
.slides {
  border-top: 9px solid black;
  border-width: 3px;
  width: 65px;
}

@media screen and (max-width: 592px) {
  .complete-container {
    margin-top: 3rem !important;
  }
}
