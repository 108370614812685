.title {
  font-weight: 700;
}
.text {
  font-size: 19px;
}
.userbio-account-btn {
  background-color: var(--color);
  border: none;
  padding: 5px 28px;
  font-size: 18px;
}
.bio-img {
  width: 202px;
  height: 202px;
  border-radius: 50%;
  object-fit: cover;
}
.username {
  position: absolute;
  top: 6.6rem;
  left: 13rem;
  font-size: 19px;
}
.hero-section {
  position: relative;
}
.user-heading {
  margin-left: 0.5rem;

  font-weight: bold;
  font-size: 20px;
}
.textarea {
  font-size: 19px;
}
.camera-div {
  background: #e4e6eb;
  border-radius: 50%;
  left: 10.9rem;
  top: 8rem;
  display: flex;
  padding: 7px;
  position: absolute;
  cursor: pointer;
}
.camera-img {
  width: 20px;
  height: 20px;
}

@media only screen and (max-width: 408px) {
  .bio-img {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    object-fit: cover;
  }
  .user-heading {
    margin-left: 0.5rem;

    font-weight: bold;
    font-size: 15px;
  }

  .userbio-account-section {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media only screen and (max-width: 472px) {
  .bio-img {
    width: 148px !important;
    height: 148px !important;
  }
  .camera-div {
    left: 7.2rem;
    top: 5.5rem;
  }
  .username {
    top: 5.6rem;
    left: 10rem;
  }
}
