:root {
  --color: #383a3c;
  font-family: "Times New Roman", Times, serif;
}

.home-text {
  font-size: 16px;
}
.home-btn {
  background-color: var(--color);
  border: none;
  padding: 11px 49px;
  font-size: 17px;
}
.home-transferbtn {
  background-color: #fdba74;
  border: none;
  border-radius: 5px;
  padding: 6px 19px;
  font-size: 18px;
  cursor: pointer;
}
.home-section-second {
  /* padding-top: 6rem; */
  padding-left: 4rem;
}
.anchr-link {
  border-bottom: 1px solid #007bff;
}
.anchr-link:hover {
  text-decoration: none !important;
}
.home-data {
  font-size: 15px;
}
.nftBox {
  padding: 8px 27px;

  box-shadow: 1px 1px 8px 1px #888888;
  padding: 24px 33px;
  border-radius: 5px;
  margin: -39px 30px;
  background: white;
  position: relative;
}
.nft-btn {
  background: var(--color);
  color: white;
  padding: 5px 28px;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.afternftBox {
  padding: 8px 0px;
}
.afternftBox-readMore {
  cursor: pointer;
  font-weight: bold;
}
.transfer-heading {
  font-weight: bold;

  font-family: Urbanist;
  font-size: 30px;
  font-weight: 700;

  letter-spacing: 0em;
  text-align: left;
}
.para {
  font-family: Urbanist;
  font-size: 20px;
  font-weight: 400;

  letter-spacing: 0em;
  text-align: left;
}

.transfer-head {
  color: black;

  font-family: Urbanist;
  font-size: 16px;
  font-weight: 600;

  letter-spacing: 0em;
  text-align: left;
}
.MuiDialogContent-root {
}
.nft-heading {
  margin-bottom: 1.5rem;
}
.statement-margin {
  margin-top: 1rem;
}


.dropdown {
  position: relative;
  display: inline-block;
  align-items: flex-start;
  left: 0;
}

.dropdown img {
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  left: 0;

}

.dropdown-content {
  display: none;
  position: absolute;
  left: 0;
  z-index: 1;
  background-color: #f9f9f9;
  min-width: 100px;
  width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  border-radius: 5px;
  padding: 5px 0;
}

.dropdown-content a {
  color: #333;
  padding: 12px 16px;
  left: 0;

  text-decoration: none;
  display: block;
 text-align: left;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}


@media only screen and (max-width: 768px) {
  .home-section-second {
    padding-top: 0rem;
    padding-left: 1rem;
  }
}

@media only screen and (max-width: 400px) {
  .nft-heading {
    font-size: 28px;
  }
  .closeicon {
    padding-right: 0px !important;
  }
}

@media only screen and (max-width: 992px) {
  .home-section-second {
    padding-top: 0rem;
    padding-left: 1rem;
  }
}


