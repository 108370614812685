.containerOrdinal {
    display: flex;
    /* justify-content: space-around; */
    padding: 20px;
  }
  
  .pdf-viewer iframe {
    width: 1000px; /* Adjust as needed */
    height: 800px; /* Adjust as needed */
    margin-left: 20px;
    border: none;
    /* More styles if needed */
  }
  
  .ordinal-details {
    width: 30%; /* Adjust as needed */
    display: flex;
    margin-left: 60px;
    flex-direction: column;
    padding-top:20px;
    align-self: center;
    /* box-sizing: border-box; */
    /* Other styles as needed */
  }
  
  .dark-theme {
    background-color: #000;
    color: #fff;
  }
  
  .detail {
    margin-bottom: 10px;
  }
  
  .label {
    font-weight: bold;
    margin-right: 5px;
    display: inline;
    width:200px;
    color:#9a9790;
  }
  
  .value {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 15px;
    border-bottom: 1px solid #9a9790;
  }
  
  .link {
    color: #4a90e2; /* Or any other color for links */
    text-decoration: none;
  }