.tab-link {
  color: black;
  background-color: none !important;
  font-size: 20px;
  font-weight: bold !important;
  /* background-color: green; */
  /* background: linear-gradient(to right, #00b300, #8bc34a); */

  margin-left: 20px;
  /* color: white; */
  padding: 10px 20px;
  border: none;
  border-radius: 50px !important;
  display: inline-block;
  cursor: pointer;
}
.tab-link:hover {
  color: var(--color) !important;
}
.nav-link.active {
  /* background-color: none !important; */
  background: linear-gradient(to right, #00b300, #8bc34a);

}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  /* color: var(--color) !important  ; */
  color: black !important;
  /* background: none !important; */
}


@media only screen and (max-width: 408px) {
  .tab-link {
    font-size: 16px;
  }
}
