.title {
  color: var(--color);
  font-size: 30px;
  font-weight: 700;
}

.text {
  font-size: 16px !important;
}
.terms-text {
  color: var(--color) !important;
  font-size: 17px;
}

.form-check {
  padding-left: 0rem !important;
}
.register-text {
  font-size: 15px;
}
.register-btn {
  background-color: var(--color);
  border: none;
  padding: 5px 28px;
  font-size: 20px;
}
.register-btn:hover {
  background-color: #101010 !important;
}

.register-btndisable {
  background: grey !important;
  border: none !important;
  padding: 5px 28px;
  font-size: 18px;
}
.register-btndisable:hover {
  background: grey !important;
  border: none !important;
}
.register-img {
  width: 100%;
}

/* media query */
@media (max-width: 990px) and (min-width: 768px) {
  .register-img {
    margin-bottom: 1rem;
  }
}
@media (max-width: 990px) {
  .register-img {
    margin-bottom: 2rem;
  }
}

/* login */

Link:hover {
  text-decoration: none !important;
}
.login-text {
  font-size: 16px;
}
.login-btn {
  background-color: var(--color);
  border: none;
  padding: 5px 28px;
  font-size: 18px;
}
.login-btn:hover {
  background-color: #101010 !important;
}

/* verify email */

.verifyemail-text {
  font-size: 16px;
}
.verifyemail-btn {
  background-color: var(--color);
  border: none;
  padding: 11px 49px;
  font-size: 18px;
}
.verifyemail-section-second {
  padding-top: 3rem;
}
/* forgot password */
.forgot-desc {
  font-size: 16px !important;
}


/* .custom-select {
  background-color: #fff;
  border: 2px solid #ccc;
  border-radius: 5px;
  color: #333;
  font-size: 16px;
  height: 40px;
  padding: 5px 10px;
  width: 200px;
}

.custom-select option:first-child {
  color: #999;
}

.custom-select:focus {
  border-color: #4d90fe;
  outline: none;
} */